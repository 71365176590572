/* tslint:disable */
/* eslint-disable */
/**
 * My Title
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import { Configuration } from './configuration';
import globalAxios, { AxiosPromise, AxiosInstance } from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from './common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from './base';

/**
 * 
 * @export
 * @interface AddressDTO
 */
export interface AddressDTO {
    /**
     * 
     * @type {number}
     * @memberof AddressDTO
     */
    id?: number;
    /**
     * 
     * @type {string}
     * @memberof AddressDTO
     */
    addressTypeId?: string;
    /**
     * 
     * @type {string}
     * @memberof AddressDTO
     */
    name?: string;
    /**
     * 
     * @type {string}
     * @memberof AddressDTO
     */
    unitNumber?: string;
    /**
     * 
     * @type {string}
     * @memberof AddressDTO
     */
    complexName?: string;
    /**
     * 
     * @type {string}
     * @memberof AddressDTO
     */
    streetNumber?: string;
    /**
     * 
     * @type {string}
     * @memberof AddressDTO
     */
    streetName?: string;
    /**
     * 
     * @type {string}
     * @memberof AddressDTO
     */
    suburb?: string;
    /**
     * 
     * @type {string}
     * @memberof AddressDTO
     */
    city?: string;
    /**
     * 
     * @type {string}
     * @memberof AddressDTO
     */
    provinceId?: string;
    /**
     * 
     * @type {string}
     * @memberof AddressDTO
     */
    country?: string;
    /**
     * 
     * @type {string}
     * @memberof AddressDTO
     */
    postalCode?: string;
    /**
     * 
     * @type {boolean}
     * @memberof AddressDTO
     */
    isPrimary?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof AddressDTO
     */
    confirmed?: boolean;
    /**
     * 
     * @type {string}
     * @memberof AddressDTO
     */
    latitude?: string;
    /**
     * 
     * @type {string}
     * @memberof AddressDTO
     */
    longitude: string;
}
/**
 * 
 * @export
 * @interface AppDTO
 */
export interface AppDTO {
    /**
     * 
     * @type {number}
     * @memberof AppDTO
     */
    id: number;
    /**
     * 
     * @type {string}
     * @memberof AppDTO
     */
    appID: string;
    /**
     * 
     * @type {string}
     * @memberof AppDTO
     */
    deviceID: string;
    /**
     * 
     * @type {string}
     * @memberof AppDTO
     */
    pushToken?: string;
    /**
     * 
     * @type {string}
     * @memberof AppDTO
     */
    version?: string;
}
/**
 * 
 * @export
 * @interface AssetDTO
 */
export interface AssetDTO {
    /**
     * 
     * @type {string}
     * @memberof AssetDTO
     */
    profileId: string;
    /**
     * 
     * @type {number}
     * @memberof AssetDTO
     */
    id?: number;
    /**
     * 
     * @type {string}
     * @memberof AssetDTO
     */
    insurer?: string;
    /**
     * 
     * @type {string}
     * @memberof AssetDTO
     */
    assetType: string;
    /**
     * 
     * @type {AssetProperties}
     * @memberof AssetDTO
     */
    properties?: AssetProperties;
    /**
     * 
     * @type {string}
     * @memberof AssetDTO
     */
    title: string;
    /**
     * 
     * @type {string}
     * @memberof AssetDTO
     */
    description?: string;
}
/**
 * 
 * @export
 * @interface AssetProperties
 */
export interface AssetProperties {
    /**
     * 
     * @type {Array<KeyValue>}
     * @memberof AssetProperties
     */
    props: Array<KeyValue>;
}
/**
 * 
 * @export
 * @interface CartDTO
 */
export interface CartDTO {
    /**
     * 
     * @type {string}
     * @memberof CartDTO
     */
    version: string;
    /**
     * 
     * @type {string}
     * @memberof CartDTO
     */
    updated: string;
    /**
     * 
     * @type {Array<LineItemDTO>}
     * @memberof CartDTO
     */
    lines: Array<LineItemDTO>;
    /**
     * 
     * @type {CartState}
     * @memberof CartDTO
     */
    state: CartState;
}
/**
 * 
 * @export
 * @interface CartProductDto
 */
export interface CartProductDto {
    /**
     * 
     * @type {number}
     * @memberof CartProductDto
     */
    id: number;
    /**
     * 
     * @type {string}
     * @memberof CartProductDto
     */
    title: string;
    /**
     * 
     * @type {string}
     * @memberof CartProductDto
     */
    description: string;
    /**
     * 
     * @type {number}
     * @memberof CartProductDto
     */
    price: number;
    /**
     * 
     * @type {string}
     * @memberof CartProductDto
     */
    formattedprice: string;
    /**
     * 
     * @type {number}
     * @memberof CartProductDto
     */
    vat: number;
    /**
     * 
     * @type {string}
     * @memberof CartProductDto
     */
    image?: string;
    /**
     * 
     * @type {boolean}
     * @memberof CartProductDto
     */
    isPaid?: boolean;
}
/**
 * 
 * @export
 * @enum {string}
 */

export enum CartState {
    Open = 'Open',
    Closed = 'Closed',
    Paid = 'Paid',
    Unpaid = 'Unpaid'
}

/**
 * 
 * @export
 * @interface ContactDTO
 */
export interface ContactDTO {
    /**
     * 
     * @type {number}
     * @memberof ContactDTO
     */
    id: number;
    /**
     * 
     * @type {string}
     * @memberof ContactDTO
     */
    profileOwnerId: string;
    /**
     * 
     * @type {boolean}
     * @memberof ContactDTO
     */
    isEmergency: boolean;
    /**
     * 
     * @type {number}
     * @memberof ContactDTO
     */
    emergencyPriority: number;
    /**
     * 
     * @type {Array<ContactType>}
     * @memberof ContactDTO
     */
    contactType: Array<ContactType>;
    /**
     * 
     * @type {RelationshipType}
     * @memberof ContactDTO
     */
    relationshipType?: RelationshipType;
    /**
     * 
     * @type {boolean}
     * @memberof ContactDTO
     */
    isMember: boolean;
    /**
     * 
     * @type {string}
     * @memberof ContactDTO
     */
    contactProfile?: string;
}
/**
 * 
 * @export
 * @interface ContactType
 */
export interface ContactType {
    /**
     * 
     * @type {string}
     * @memberof ContactType
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof ContactType
     */
    text: string;
    /**
     * 
     * @type {Status}
     * @memberof ContactType
     */
    status: Status;
}
/**
 * 
 * @export
 * @interface DeviceDTO
 */
export interface DeviceDTO {
    /**
     * 
     * @type {number}
     * @memberof DeviceDTO
     */
    id?: number;
    /**
     * 
     * @type {string}
     * @memberof DeviceDTO
     */
    name?: string;
    /**
     * 
     * @type {string}
     * @memberof DeviceDTO
     */
    phoneNumber?: string;
    /**
     * 
     * @type {string}
     * @memberof DeviceDTO
     */
    deviceID?: string;
    /**
     * 
     * @type {string}
     * @memberof DeviceDTO
     */
    imei?: string;
    /**
     * 
     * @type {string}
     * @memberof DeviceDTO
     */
    make?: string;
    /**
     * 
     * @type {string}
     * @memberof DeviceDTO
     */
    model?: string;
    /**
     * 
     * @type {OS}
     * @memberof DeviceDTO
     */
    osType?: OS;
    /**
     * 
     * @type {Array<AppDTO>}
     * @memberof DeviceDTO
     */
    apps?: Array<AppDTO>;
}
/**
 * 
 * @export
 * @interface DocumentDTO
 */
export interface DocumentDTO {
    /**
     * 
     * @type {number}
     * @memberof DocumentDTO
     */
    id: number;
    /**
     * 
     * @type {DocumentType}
     * @memberof DocumentDTO
     */
    documentType?: DocumentType;
    /**
     * 
     * @type {number}
     * @memberof DocumentDTO
     */
    sizeBytes: number;
    /**
     * 
     * @type {string}
     * @memberof DocumentDTO
     */
    location: string;
    /**
     * 
     * @type {string}
     * @memberof DocumentDTO
     */
    uploaded: string;
    /**
     * 
     * @type {string}
     * @memberof DocumentDTO
     */
    filename: string;
    /**
     * 
     * @type {string}
     * @memberof DocumentDTO
     */
    storeId?: string;
    /**
     * 
     * @type {string}
     * @memberof DocumentDTO
     */
    reference?: string;
    /**
     * 
     * @type {string}
     * @memberof DocumentDTO
     */
    uploadedTo?: string;
    /**
     * 
     * @type {string}
     * @memberof DocumentDTO
     */
    category?: string;
}
/**
 * 
 * @export
 * @interface DocumentType
 */
export interface DocumentType {
    /**
     * 
     * @type {string}
     * @memberof DocumentType
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof DocumentType
     */
    text: string;
    /**
     * 
     * @type {Status}
     * @memberof DocumentType
     */
    status: Status;
}
/**
 * 
 * @export
 * @interface ErpcDTO
 */
export interface ErpcDTO {
    /**
     * 
     * @type {number}
     * @memberof ErpcDTO
     */
    id: number;
    /**
     * 
     * @type {string}
     * @memberof ErpcDTO
     */
    company: string;
    /**
     * 
     * @type {string}
     * @memberof ErpcDTO
     */
    branchName: string;
    /**
     * 
     * @type {string}
     * @memberof ErpcDTO
     */
    companyLogo?: string;
    /**
     * 
     * @type {string}
     * @memberof ErpcDTO
     */
    contactNo: string;
    /**
     * 
     * @type {string}
     * @memberof ErpcDTO
     */
    address: string;
}
/**
 * 
 * @export
 * @interface FileRequest
 */
export interface FileRequest {
    /**
     * 
     * @type {string}
     * @memberof FileRequest
     */
    contentType: string;
    /**
     * 
     * @type {string}
     * @memberof FileRequest
     */
    filePath: string;
}
/**
 * 
 * @export
 * @interface KeyValue
 */
export interface KeyValue {
    /**
     * 
     * @type {string}
     * @memberof KeyValue
     */
    key: string;
    /**
     * 
     * @type {string}
     * @memberof KeyValue
     */
    value: string;
}
/**
 * 
 * @export
 * @interface KeyValuePairOfStringAndStringValues
 */
export interface KeyValuePairOfStringAndStringValues {
    /**
     * 
     * @type {string}
     * @memberof KeyValuePairOfStringAndStringValues
     */
    key: string;
    /**
     * 
     * @type {Array<object>}
     * @memberof KeyValuePairOfStringAndStringValues
     */
    value: Array<object>;
}
/**
 * 
 * @export
 * @interface LineItemDTO
 */
export interface LineItemDTO {
    /**
     * 
     * @type {CartProductDto}
     * @memberof LineItemDTO
     */
    product: CartProductDto;
    /**
     * 
     * @type {number}
     * @memberof LineItemDTO
     */
    quantity: number;
}
/**
 * 
 * @export
 * @enum {string}
 */

export enum OS {
    Ios = 'IOS',
    Android = 'ANDROID'
}

/**
 * 
 * @export
 * @interface Option
 */
export interface Option {
    /**
     * 
     * @type {string}
     * @memberof Option
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof Option
     */
    text: string;
    /**
     * 
     * @type {Status}
     * @memberof Option
     */
    status: Status;
}
/**
 * 
 * @export
 * @interface OptionDTO
 */
export interface OptionDTO {
    /**
     * 
     * @type {string}
     * @memberof OptionDTO
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof OptionDTO
     */
    text: string;
}
/**
 * 
 * @export
 * @interface PhoneDTO
 */
export interface PhoneDTO {
    /**
     * 
     * @type {number}
     * @memberof PhoneDTO
     */
    id: number;
    /**
     * 
     * @type {string}
     * @memberof PhoneDTO
     */
    phoneNumber: string;
    /**
     * 
     * @type {PhoneNumberType}
     * @memberof PhoneDTO
     */
    type: PhoneNumberType;
    /**
     * 
     * @type {string}
     * @memberof PhoneDTO
     */
    extension?: string;
    /**
     * 
     * @type {string}
     * @memberof PhoneDTO
     */
    countryCode?: string;
    /**
     * 
     * @type {boolean}
     * @memberof PhoneDTO
     */
    isPrimary: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof PhoneDTO
     */
    isConfirmed: boolean;
}
/**
 * 
 * @export
 * @enum {string}
 */

export enum PhoneNumberType {
    Main = 'Main',
    Work = 'Work',
    Home = 'Home',
    Alternate = 'Alternate',
    Emergency = 'Emergency'
}

/**
 * 
 * @export
 * @interface ProductDTO
 */
export interface ProductDTO {
    /**
     * 
     * @type {number}
     * @memberof ProductDTO
     */
    id: number;
    /**
     * 
     * @type {string}
     * @memberof ProductDTO
     */
    title: string;
    /**
     * 
     * @type {string}
     * @memberof ProductDTO
     */
    description: string;
    /**
     * 
     * @type {number}
     * @memberof ProductDTO
     */
    price: number;
    /**
     * 
     * @type {string}
     * @memberof ProductDTO
     */
    formattedprice: string;
    /**
     * 
     * @type {number}
     * @memberof ProductDTO
     */
    vat: number;
    /**
     * 
     * @type {string}
     * @memberof ProductDTO
     */
    image?: string;
    /**
     * 
     * @type {boolean}
     * @memberof ProductDTO
     */
    isPaid?: boolean;
}
/**
 * 
 * @export
 * @interface ProfileDTO
 */
export interface ProfileDTO {
    /**
     * 
     * @type {string}
     * @memberof ProfileDTO
     */
    guid: string;
    /**
     * 
     * @type {string}
     * @memberof ProfileDTO
     */
    tbxPassportId: string;
    /**
     * 
     * @type {string}
     * @memberof ProfileDTO
     */
    connectMeCode: string;
    /**
     * 
     * @type {string}
     * @memberof ProfileDTO
     */
    created?: string;
    /**
     * 
     * @type {string}
     * @memberof ProfileDTO
     */
    lastUpdated?: string;
    /**
     * 
     * @type {string}
     * @memberof ProfileDTO
     */
    firstNames: string;
    /**
     * 
     * @type {string}
     * @memberof ProfileDTO
     */
    lastName: string;
    /**
     * 
     * @type {string}
     * @memberof ProfileDTO
     */
    genderIdentityId?: string;
    /**
     * 
     * @type {string}
     * @memberof ProfileDTO
     */
    mainLanguageId?: string;
    /**
     * 
     * @type {string}
     * @memberof ProfileDTO
     */
    dateOfBirth?: string;
    /**
     * 
     * @type {string}
     * @memberof ProfileDTO
     */
    idNumber?: string;
    /**
     * 
     * @type {string}
     * @memberof ProfileDTO
     */
    passportNumber?: string;
    /**
     * 
     * @type {string}
     * @memberof ProfileDTO
     */
    occupationalSector?: string;
    /**
     * 
     * @type {string}
     * @memberof ProfileDTO
     */
    maritalStatus?: string;
    /**
     * 
     * @type {number}
     * @memberof ProfileDTO
     */
    numberOfChildDependents?: number;
    /**
     * 
     * @type {number}
     * @memberof ProfileDTO
     */
    numberOfAdultDependents?: number;
    /**
     * 
     * @type {Array<AddressDTO>}
     * @memberof ProfileDTO
     */
    addresses?: Array<AddressDTO>;
    /**
     * 
     * @type {Array<PhoneDTO>}
     * @memberof ProfileDTO
     */
    phoneNumbers?: Array<PhoneDTO>;
    /**
     * 
     * @type {string}
     * @memberof ProfileDTO
     */
    bio?: string;
    /**
     * 
     * @type {string}
     * @memberof ProfileDTO
     */
    mainCell: string;
    /**
     * 
     * @type {string}
     * @memberof ProfileDTO
     */
    email: string;
    /**
     * 
     * @type {string}
     * @memberof ProfileDTO
     */
    profilePhoto?: string;
    /**
     * 
     * @type {string}
     * @memberof ProfileDTO
     */
    distinctiveFeatures?: string;
    /**
     * 
     * @type {boolean}
     * @memberof ProfileDTO
     */
    glasses?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof ProfileDTO
     */
    contactLenses?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof ProfileDTO
     */
    falseTeeth?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof ProfileDTO
     */
    hearingAids?: boolean;
    /**
     * 
     * @type {string}
     * @memberof ProfileDTO
     */
    tattoos?: string;
    /**
     * 
     * @type {string}
     * @memberof ProfileDTO
     */
    piercings?: string;
    /**
     * 
     * @type {string}
     * @memberof ProfileDTO
     */
    heightAndBuild?: string;
    /**
     * 
     * @type {string}
     * @memberof ProfileDTO
     */
    hairColour?: string;
    /**
     * 
     * @type {string}
     * @memberof ProfileDTO
     */
    eyeColour?: string;
    /**
     * 
     * @type {string}
     * @memberof ProfileDTO
     */
    race?: string;
    /**
     * 
     * @type {ErpcDTO}
     * @memberof ProfileDTO
     */
    company?: ErpcDTO;
    /**
     * 
     * @type {string}
     * @memberof ProfileDTO
     */
    telegramId?: string;
    /**
     * 
     * @type {number}
     * @memberof ProfileDTO
     */
    userStatus?: number;
    /**
     * 
     * @type {Array<ContactDTO>}
     * @memberof ProfileDTO
     */
    contacts?: Array<ContactDTO>;
}
/**
 * 
 * @export
 * @interface RelationshipType
 */
export interface RelationshipType {
    /**
     * 
     * @type {string}
     * @memberof RelationshipType
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof RelationshipType
     */
    text: string;
    /**
     * 
     * @type {Status}
     * @memberof RelationshipType
     */
    status: Status;
}
/**
 * 
 * @export
 * @interface ReminderDTO
 */
export interface ReminderDTO {
    /**
     * 
     * @type {number}
     * @memberof ReminderDTO
     */
    id?: number;
    /**
     * 
     * @type {string}
     * @memberof ReminderDTO
     */
    name: string;
    /**
     * 
     * @type {string}
     * @memberof ReminderDTO
     */
    reference?: string;
    /**
     * 
     * @type {string}
     * @memberof ReminderDTO
     */
    description?: string;
    /**
     * 
     * @type {string}
     * @memberof ReminderDTO
     */
    ownerGuid?: string;
    /**
     * 
     * @type {string}
     * @memberof ReminderDTO
     */
    savedTo?: string;
    /**
     * 
     * @type {string}
     * @memberof ReminderDTO
     */
    category?: string;
    /**
     * 
     * @type {string}
     * @memberof ReminderDTO
     */
    trigger?: string;
    /**
     * 
     * @type {string}
     * @memberof ReminderDTO
     */
    createDate?: string;
    /**
     * 
     * @type {string}
     * @memberof ReminderDTO
     */
    lastModified?: string;
    /**
     * 
     * @type {boolean}
     * @memberof ReminderDTO
     */
    repeat?: boolean;
}
/**
 * 
 * @export
 * @interface Status
 */
export interface Status {
    /**
     * 
     * @type {string}
     * @memberof Status
     */
    key: string;
    /**
     * 
     * @type {string}
     * @memberof Status
     */
    description: string;
}
/**
 * 
 * @export
 * @interface TicketDTO
 */
export interface TicketDTO {
    /**
     * 
     * @type {number}
     * @memberof TicketDTO
     */
    id?: number;
    /**
     * 
     * @type {string}
     * @memberof TicketDTO
     */
    ownerId?: string;
    /**
     * 
     * @type {string}
     * @memberof TicketDTO
     */
    officerId?: string;
    /**
     * 
     * @type {string}
     * @memberof TicketDTO
     */
    title?: string;
    /**
     * 
     * @type {string}
     * @memberof TicketDTO
     */
    description?: string;
    /**
     * 
     * @type {number}
     * @memberof TicketDTO
     */
    originalTicketId?: number;
    /**
     * 
     * @type {string}
     * @memberof TicketDTO
     */
    reported?: string;
}

/**
 * DeviceApi - axios parameter creator
 * @export
 */
export const DeviceApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deviceGetDevices: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/Device/GetDevices`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {AppDTO} request 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deviceRegisterApp: async (request: AppDTO, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'request' is not null or undefined
            assertParamExists('deviceRegisterApp', 'request', request)
            const localVarPath = `/Device/RegisterApp`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(request, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {DeviceDTO} request 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deviceRegisterDevice: async (request: DeviceDTO, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'request' is not null or undefined
            assertParamExists('deviceRegisterDevice', 'request', request)
            const localVarPath = `/Device/RegisterDevice`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(request, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * DeviceApi - functional programming interface
 * @export
 */
export const DeviceApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = DeviceApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deviceGetDevices(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<DeviceDTO>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deviceGetDevices(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {AppDTO} request 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deviceRegisterApp(request: AppDTO, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deviceRegisterApp(request, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {DeviceDTO} request 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deviceRegisterDevice(request: DeviceDTO, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DeviceDTO>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deviceRegisterDevice(request, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * DeviceApi - factory interface
 * @export
 */
export const DeviceApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = DeviceApiFp(configuration)
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deviceGetDevices(options?: any): AxiosPromise<Array<DeviceDTO>> {
            return localVarFp.deviceGetDevices(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {AppDTO} request 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deviceRegisterApp(request: AppDTO, options?: any): AxiosPromise<string> {
            return localVarFp.deviceRegisterApp(request, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {DeviceDTO} request 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deviceRegisterDevice(request: DeviceDTO, options?: any): AxiosPromise<DeviceDTO> {
            return localVarFp.deviceRegisterDevice(request, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * DeviceApi - object-oriented interface
 * @export
 * @class DeviceApi
 * @extends {BaseAPI}
 */
export class DeviceApi extends BaseAPI {
    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DeviceApi
     */
    public deviceGetDevices(options?: any) {
        return DeviceApiFp(this.configuration).deviceGetDevices(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {AppDTO} request 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DeviceApi
     */
    public deviceRegisterApp(request: AppDTO, options?: any) {
        return DeviceApiFp(this.configuration).deviceRegisterApp(request, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {DeviceDTO} request 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DeviceApi
     */
    public deviceRegisterDevice(request: DeviceDTO, options?: any) {
        return DeviceApiFp(this.configuration).deviceRegisterDevice(request, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * FileApi - axios parameter creator
 * @export
 */
export const FileApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        fileGetFileLinkById: async (id: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('fileGetFileLinkById', 'id', id)
            const localVarPath = `/File/GetFileLink/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} key 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        fileGetFileLinkByKey: async (key: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'key' is not null or undefined
            assertParamExists('fileGetFileLinkByKey', 'key', key)
            const localVarPath = `/File/GetFileByKey/{key}`
                .replace(`{${"key"}}`, encodeURIComponent(String(key)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        fileListFiles: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/File/ListFiles`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {Array<KeyValuePairOfStringAndStringValues>} [formdata] 
         * @param {any} [file] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        fileNewDocument: async (formdata?: Array<KeyValuePairOfStringAndStringValues>, file?: any, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/File/NewDocument`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            const localVarFormParams = new ((configuration && configuration.formDataCtor) || FormData)();

            if (formdata) {
                localVarQueryParameter['formdata'] = formdata;
            }


            if (file !== undefined) { 
                localVarFormParams.append('file', file as any);
            }
    
    
            localVarHeaderParameter['Content-Type'] = 'multipart/form-data';
    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = localVarFormParams;

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {any} [file] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        fileNewSelfie: async (file?: any, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/File/NewSelfie`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            const localVarFormParams = new ((configuration && configuration.formDataCtor) || FormData)();


            if (file !== undefined) { 
                localVarFormParams.append('file', file as any);
            }
    
    
            localVarHeaderParameter['Content-Type'] = 'multipart/form-data';
    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = localVarFormParams;

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {FileRequest} request 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        fileSignForUpload: async (request: FileRequest, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'request' is not null or undefined
            assertParamExists('fileSignForUpload', 'request', request)
            const localVarPath = `/File/SignForUpload`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(request, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {any} [files] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        fileUploadDirect: async (files?: any, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/File/UploadDirect`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            const localVarFormParams = new ((configuration && configuration.formDataCtor) || FormData)();


            if (files !== undefined) { 
                localVarFormParams.append('files', files as any);
            }
    
    
            localVarHeaderParameter['Content-Type'] = 'multipart/form-data';
    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = localVarFormParams;

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * FileApi - functional programming interface
 * @export
 */
export const FileApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = FileApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async fileGetFileLinkById(id: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.fileGetFileLinkById(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} key 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async fileGetFileLinkByKey(key: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.fileGetFileLinkByKey(key, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async fileListFiles(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<DocumentDTO>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.fileListFiles(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {Array<KeyValuePairOfStringAndStringValues>} [formdata] 
         * @param {any} [file] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async fileNewDocument(formdata?: Array<KeyValuePairOfStringAndStringValues>, file?: any, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.fileNewDocument(formdata, file, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {any} [file] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async fileNewSelfie(file?: any, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.fileNewSelfie(file, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {FileRequest} request 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async fileSignForUpload(request: FileRequest, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ReminderDTO>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.fileSignForUpload(request, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {any} [files] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async fileUploadDirect(files?: any, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.fileUploadDirect(files, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * FileApi - factory interface
 * @export
 */
export const FileApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = FileApiFp(configuration)
    return {
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        fileGetFileLinkById(id: number, options?: any): AxiosPromise<any> {
            return localVarFp.fileGetFileLinkById(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} key 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        fileGetFileLinkByKey(key: string, options?: any): AxiosPromise<any> {
            return localVarFp.fileGetFileLinkByKey(key, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        fileListFiles(options?: any): AxiosPromise<Array<DocumentDTO>> {
            return localVarFp.fileListFiles(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {Array<KeyValuePairOfStringAndStringValues>} [formdata] 
         * @param {any} [file] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        fileNewDocument(formdata?: Array<KeyValuePairOfStringAndStringValues>, file?: any, options?: any): AxiosPromise<any> {
            return localVarFp.fileNewDocument(formdata, file, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {any} [file] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        fileNewSelfie(file?: any, options?: any): AxiosPromise<any> {
            return localVarFp.fileNewSelfie(file, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {FileRequest} request 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        fileSignForUpload(request: FileRequest, options?: any): AxiosPromise<ReminderDTO> {
            return localVarFp.fileSignForUpload(request, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {any} [files] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        fileUploadDirect(files?: any, options?: any): AxiosPromise<any> {
            return localVarFp.fileUploadDirect(files, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * FileApi - object-oriented interface
 * @export
 * @class FileApi
 * @extends {BaseAPI}
 */
export class FileApi extends BaseAPI {
    /**
     * 
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FileApi
     */
    public fileGetFileLinkById(id: number, options?: any) {
        return FileApiFp(this.configuration).fileGetFileLinkById(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} key 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FileApi
     */
    public fileGetFileLinkByKey(key: string, options?: any) {
        return FileApiFp(this.configuration).fileGetFileLinkByKey(key, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FileApi
     */
    public fileListFiles(options?: any) {
        return FileApiFp(this.configuration).fileListFiles(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {Array<KeyValuePairOfStringAndStringValues>} [formdata] 
     * @param {any} [file] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FileApi
     */
    public fileNewDocument(formdata?: Array<KeyValuePairOfStringAndStringValues>, file?: any, options?: any) {
        return FileApiFp(this.configuration).fileNewDocument(formdata, file, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {any} [file] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FileApi
     */
    public fileNewSelfie(file?: any, options?: any) {
        return FileApiFp(this.configuration).fileNewSelfie(file, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {FileRequest} request 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FileApi
     */
    public fileSignForUpload(request: FileRequest, options?: any) {
        return FileApiFp(this.configuration).fileSignForUpload(request, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {any} [files] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FileApi
     */
    public fileUploadDirect(files?: any, options?: any) {
        return FileApiFp(this.configuration).fileUploadDirect(files, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * HelpDeskApi - axios parameter creator
 * @export
 */
export const HelpDeskApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        helpDeskGetConnectionString: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/HelpDesk/GetConnectionString`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        helpDeskGetGroupedHelpDeskTickets: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/HelpDesk/MyGroupedHelpDeskRequests`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        helpDeskGetOwnHelpDeskTickets: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/HelpDesk/MyHelpDeskRequests`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {TicketDTO} ticket 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        helpDeskNewHelpDeskRequest: async (ticket: TicketDTO, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'ticket' is not null or undefined
            assertParamExists('helpDeskNewHelpDeskRequest', 'ticket', ticket)
            const localVarPath = `/HelpDesk/NewHelpDeskRequest`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(ticket, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        helpDeskTestBus: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/HelpDesk/TestBus`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * HelpDeskApi - functional programming interface
 * @export
 */
export const HelpDeskApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = HelpDeskApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async helpDeskGetConnectionString(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.helpDeskGetConnectionString(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async helpDeskGetGroupedHelpDeskTickets(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<Array<TicketDTO>>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.helpDeskGetGroupedHelpDeskTickets(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async helpDeskGetOwnHelpDeskTickets(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<TicketDTO>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.helpDeskGetOwnHelpDeskTickets(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {TicketDTO} ticket 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async helpDeskNewHelpDeskRequest(ticket: TicketDTO, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<TicketDTO>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.helpDeskNewHelpDeskRequest(ticket, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async helpDeskTestBus(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.helpDeskTestBus(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * HelpDeskApi - factory interface
 * @export
 */
export const HelpDeskApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = HelpDeskApiFp(configuration)
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        helpDeskGetConnectionString(options?: any): AxiosPromise<string> {
            return localVarFp.helpDeskGetConnectionString(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        helpDeskGetGroupedHelpDeskTickets(options?: any): AxiosPromise<Array<Array<TicketDTO>>> {
            return localVarFp.helpDeskGetGroupedHelpDeskTickets(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        helpDeskGetOwnHelpDeskTickets(options?: any): AxiosPromise<Array<TicketDTO>> {
            return localVarFp.helpDeskGetOwnHelpDeskTickets(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {TicketDTO} ticket 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        helpDeskNewHelpDeskRequest(ticket: TicketDTO, options?: any): AxiosPromise<TicketDTO> {
            return localVarFp.helpDeskNewHelpDeskRequest(ticket, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        helpDeskTestBus(options?: any): AxiosPromise<string> {
            return localVarFp.helpDeskTestBus(options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * HelpDeskApi - object-oriented interface
 * @export
 * @class HelpDeskApi
 * @extends {BaseAPI}
 */
export class HelpDeskApi extends BaseAPI {
    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof HelpDeskApi
     */
    public helpDeskGetConnectionString(options?: any) {
        return HelpDeskApiFp(this.configuration).helpDeskGetConnectionString(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof HelpDeskApi
     */
    public helpDeskGetGroupedHelpDeskTickets(options?: any) {
        return HelpDeskApiFp(this.configuration).helpDeskGetGroupedHelpDeskTickets(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof HelpDeskApi
     */
    public helpDeskGetOwnHelpDeskTickets(options?: any) {
        return HelpDeskApiFp(this.configuration).helpDeskGetOwnHelpDeskTickets(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {TicketDTO} ticket 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof HelpDeskApi
     */
    public helpDeskNewHelpDeskRequest(ticket: TicketDTO, options?: any) {
        return HelpDeskApiFp(this.configuration).helpDeskNewHelpDeskRequest(ticket, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof HelpDeskApi
     */
    public helpDeskTestBus(options?: any) {
        return HelpDeskApiFp(this.configuration).helpDeskTestBus(options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * IdentityApi - axios parameter creator
 * @export
 */
export const IdentityApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        identityGet: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/identity`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * IdentityApi - functional programming interface
 * @export
 */
export const IdentityApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = IdentityApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async identityGet(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.identityGet(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * IdentityApi - factory interface
 * @export
 */
export const IdentityApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = IdentityApiFp(configuration)
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        identityGet(options?: any): AxiosPromise<any> {
            return localVarFp.identityGet(options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * IdentityApi - object-oriented interface
 * @export
 * @class IdentityApi
 * @extends {BaseAPI}
 */
export class IdentityApi extends BaseAPI {
    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof IdentityApi
     */
    public identityGet(options?: any) {
        return IdentityApiFp(this.configuration).identityGet(options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * ProfileApi - axios parameter creator
 * @export
 */
export const ProfileApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {AddressDTO} address 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        profileAddAddress: async (address: AddressDTO, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'address' is not null or undefined
            assertParamExists('profileAddAddress', 'address', address)
            const localVarPath = `/Profile/AddAddress`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(address, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        profileAssetTypes: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/Profile/AssetTypes`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        profileFakeAsset: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/Profile/AddAssetFake`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} optiontype 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        profileGetOptions: async (optiontype: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'optiontype' is not null or undefined
            assertParamExists('profileGetOptions', 'optiontype', optiontype)
            const localVarPath = `/Profile/GetOptions/{optiontype}`
                .replace(`{${"optiontype"}}`, encodeURIComponent(String(optiontype)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        profileGetProfile: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/Profile/MyProfile`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        profileMyAssets: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/Profile/MyAssets`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        profileMyProducts: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/Profile/MyProducts`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {AssetDTO} asset 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        profilePostAsset: async (asset: AssetDTO, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'asset' is not null or undefined
            assertParamExists('profilePostAsset', 'asset', asset)
            const localVarPath = `/Profile/AddAsset`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(asset, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {ProfileDTO} profile 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        profileUpdateOwnProfile: async (profile: ProfileDTO, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'profile' is not null or undefined
            assertParamExists('profileUpdateOwnProfile', 'profile', profile)
            const localVarPath = `/Profile/UpdateOwnProfile`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(profile, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ProfileApi - functional programming interface
 * @export
 */
export const ProfileApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = ProfileApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {AddressDTO} address 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async profileAddAddress(address: AddressDTO, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ProfileDTO>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.profileAddAddress(address, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async profileAssetTypes(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<OptionDTO>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.profileAssetTypes(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async profileFakeAsset(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AssetDTO>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.profileFakeAsset(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} optiontype 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async profileGetOptions(optiontype: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<OptionDTO>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.profileGetOptions(optiontype, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async profileGetProfile(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ProfileDTO>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.profileGetProfile(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async profileMyAssets(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<AssetDTO>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.profileMyAssets(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async profileMyProducts(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<ProductDTO>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.profileMyProducts(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {AssetDTO} asset 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async profilePostAsset(asset: AssetDTO, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AssetDTO>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.profilePostAsset(asset, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {ProfileDTO} profile 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async profileUpdateOwnProfile(profile: ProfileDTO, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ProfileDTO>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.profileUpdateOwnProfile(profile, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * ProfileApi - factory interface
 * @export
 */
export const ProfileApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = ProfileApiFp(configuration)
    return {
        /**
         * 
         * @param {AddressDTO} address 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        profileAddAddress(address: AddressDTO, options?: any): AxiosPromise<ProfileDTO> {
            return localVarFp.profileAddAddress(address, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        profileAssetTypes(options?: any): AxiosPromise<Array<OptionDTO>> {
            return localVarFp.profileAssetTypes(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        profileFakeAsset(options?: any): AxiosPromise<AssetDTO> {
            return localVarFp.profileFakeAsset(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} optiontype 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        profileGetOptions(optiontype: string, options?: any): AxiosPromise<Array<OptionDTO>> {
            return localVarFp.profileGetOptions(optiontype, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        profileGetProfile(options?: any): AxiosPromise<ProfileDTO> {
            return localVarFp.profileGetProfile(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        profileMyAssets(options?: any): AxiosPromise<Array<AssetDTO>> {
            return localVarFp.profileMyAssets(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        profileMyProducts(options?: any): AxiosPromise<Array<ProductDTO>> {
            return localVarFp.profileMyProducts(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {AssetDTO} asset 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        profilePostAsset(asset: AssetDTO, options?: any): AxiosPromise<AssetDTO> {
            return localVarFp.profilePostAsset(asset, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {ProfileDTO} profile 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        profileUpdateOwnProfile(profile: ProfileDTO, options?: any): AxiosPromise<ProfileDTO> {
            return localVarFp.profileUpdateOwnProfile(profile, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * ProfileApi - object-oriented interface
 * @export
 * @class ProfileApi
 * @extends {BaseAPI}
 */
export class ProfileApi extends BaseAPI {
    /**
     * 
     * @param {AddressDTO} address 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProfileApi
     */
    public profileAddAddress(address: AddressDTO, options?: any) {
        return ProfileApiFp(this.configuration).profileAddAddress(address, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProfileApi
     */
    public profileAssetTypes(options?: any) {
        return ProfileApiFp(this.configuration).profileAssetTypes(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProfileApi
     */
    public profileFakeAsset(options?: any) {
        return ProfileApiFp(this.configuration).profileFakeAsset(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} optiontype 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProfileApi
     */
    public profileGetOptions(optiontype: string, options?: any) {
        return ProfileApiFp(this.configuration).profileGetOptions(optiontype, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProfileApi
     */
    public profileGetProfile(options?: any) {
        return ProfileApiFp(this.configuration).profileGetProfile(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProfileApi
     */
    public profileMyAssets(options?: any) {
        return ProfileApiFp(this.configuration).profileMyAssets(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProfileApi
     */
    public profileMyProducts(options?: any) {
        return ProfileApiFp(this.configuration).profileMyProducts(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {AssetDTO} asset 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProfileApi
     */
    public profilePostAsset(asset: AssetDTO, options?: any) {
        return ProfileApiFp(this.configuration).profilePostAsset(asset, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {ProfileDTO} profile 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProfileApi
     */
    public profileUpdateOwnProfile(profile: ProfileDTO, options?: any) {
        return ProfileApiFp(this.configuration).profileUpdateOwnProfile(profile, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * ReminderApi - axios parameter creator
 * @export
 */
export const ReminderApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        reminderDeleteReminder: async (id: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('reminderDeleteReminder', 'id', id)
            const localVarPath = `/Reminder/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        reminderGetReminder: async (id: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('reminderGetReminder', 'id', id)
            const localVarPath = `/Reminder/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        reminderGetReminders: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/Reminder`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {ReminderDTO} reminder 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        reminderPostReminder: async (reminder: ReminderDTO, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'reminder' is not null or undefined
            assertParamExists('reminderPostReminder', 'reminder', reminder)
            const localVarPath = `/Reminder/AddReminder`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(reminder, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {ReminderDTO} reminder 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        reminderPutReminder: async (reminder: ReminderDTO, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'reminder' is not null or undefined
            assertParamExists('reminderPutReminder', 'reminder', reminder)
            const localVarPath = `/Reminder/UpdateReminder`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(reminder, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ReminderApi - functional programming interface
 * @export
 */
export const ReminderApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = ReminderApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async reminderDeleteReminder(id: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.reminderDeleteReminder(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async reminderGetReminder(id: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ReminderDTO>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.reminderGetReminder(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async reminderGetReminders(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<ReminderDTO>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.reminderGetReminders(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {ReminderDTO} reminder 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async reminderPostReminder(reminder: ReminderDTO, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ReminderDTO>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.reminderPostReminder(reminder, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {ReminderDTO} reminder 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async reminderPutReminder(reminder: ReminderDTO, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ReminderDTO>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.reminderPutReminder(reminder, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * ReminderApi - factory interface
 * @export
 */
export const ReminderApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = ReminderApiFp(configuration)
    return {
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        reminderDeleteReminder(id: number, options?: any): AxiosPromise<any> {
            return localVarFp.reminderDeleteReminder(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        reminderGetReminder(id: number, options?: any): AxiosPromise<ReminderDTO> {
            return localVarFp.reminderGetReminder(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        reminderGetReminders(options?: any): AxiosPromise<Array<ReminderDTO>> {
            return localVarFp.reminderGetReminders(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {ReminderDTO} reminder 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        reminderPostReminder(reminder: ReminderDTO, options?: any): AxiosPromise<ReminderDTO> {
            return localVarFp.reminderPostReminder(reminder, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {ReminderDTO} reminder 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        reminderPutReminder(reminder: ReminderDTO, options?: any): AxiosPromise<ReminderDTO> {
            return localVarFp.reminderPutReminder(reminder, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * ReminderApi - object-oriented interface
 * @export
 * @class ReminderApi
 * @extends {BaseAPI}
 */
export class ReminderApi extends BaseAPI {
    /**
     * 
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ReminderApi
     */
    public reminderDeleteReminder(id: number, options?: any) {
        return ReminderApiFp(this.configuration).reminderDeleteReminder(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ReminderApi
     */
    public reminderGetReminder(id: number, options?: any) {
        return ReminderApiFp(this.configuration).reminderGetReminder(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ReminderApi
     */
    public reminderGetReminders(options?: any) {
        return ReminderApiFp(this.configuration).reminderGetReminders(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {ReminderDTO} reminder 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ReminderApi
     */
    public reminderPostReminder(reminder: ReminderDTO, options?: any) {
        return ReminderApiFp(this.configuration).reminderPostReminder(reminder, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {ReminderDTO} reminder 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ReminderApi
     */
    public reminderPutReminder(reminder: ReminderDTO, options?: any) {
        return ReminderApiFp(this.configuration).reminderPutReminder(reminder, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * ShopApi - axios parameter creator
 * @export
 */
export const ShopApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {LineItemDTO} lineItemDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        shopAddToCart: async (lineItemDto: LineItemDTO, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'lineItemDto' is not null or undefined
            assertParamExists('shopAddToCart', 'lineItemDto', lineItemDto)
            const localVarPath = `/Shop/AddToCart`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(lineItemDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        shopCartPaid: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/Shop/CartPaid`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        shopClearOwnCart: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/Shop/ClearOwnCart`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {LineItemDTO} lineItemDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        shopDeleteLine: async (lineItemDto: LineItemDTO, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'lineItemDto' is not null or undefined
            assertParamExists('shopDeleteLine', 'lineItemDto', lineItemDto)
            const localVarPath = `/Shop/DeleteLine`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(lineItemDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        shopGetAnonymousCart: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/Shop/AnonCart`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        shopGetOwnCart: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/Shop/MyCart`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        shopGetStoreProducts: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/Shop/StoreProducts`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {CartDTO} cartDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        shopReplaceCart: async (cartDto: CartDTO, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'cartDto' is not null or undefined
            assertParamExists('shopReplaceCart', 'cartDto', cartDto)
            const localVarPath = `/Shop/ReplaceCart`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(cartDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} state 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        shopUpdateCartState: async (state: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'state' is not null or undefined
            assertParamExists('shopUpdateCartState', 'state', state)
            const localVarPath = `/Shop/UpdateCartState`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(state, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {LineItemDTO} lineItemDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        shopUpdateLineQuantity: async (lineItemDto: LineItemDTO, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'lineItemDto' is not null or undefined
            assertParamExists('shopUpdateLineQuantity', 'lineItemDto', lineItemDto)
            const localVarPath = `/Shop/UpdateLineQuantity`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(lineItemDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ShopApi - functional programming interface
 * @export
 */
export const ShopApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = ShopApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {LineItemDTO} lineItemDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async shopAddToCart(lineItemDto: LineItemDTO, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CartDTO>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.shopAddToCart(lineItemDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async shopCartPaid(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CartDTO>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.shopCartPaid(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async shopClearOwnCart(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CartDTO>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.shopClearOwnCart(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {LineItemDTO} lineItemDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async shopDeleteLine(lineItemDto: LineItemDTO, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CartDTO>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.shopDeleteLine(lineItemDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async shopGetAnonymousCart(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CartDTO>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.shopGetAnonymousCart(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async shopGetOwnCart(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CartDTO>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.shopGetOwnCart(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async shopGetStoreProducts(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<ProductDTO>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.shopGetStoreProducts(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {CartDTO} cartDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async shopReplaceCart(cartDto: CartDTO, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CartDTO>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.shopReplaceCart(cartDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} state 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async shopUpdateCartState(state: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CartDTO>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.shopUpdateCartState(state, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {LineItemDTO} lineItemDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async shopUpdateLineQuantity(lineItemDto: LineItemDTO, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CartDTO>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.shopUpdateLineQuantity(lineItemDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * ShopApi - factory interface
 * @export
 */
export const ShopApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = ShopApiFp(configuration)
    return {
        /**
         * 
         * @param {LineItemDTO} lineItemDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        shopAddToCart(lineItemDto: LineItemDTO, options?: any): AxiosPromise<CartDTO> {
            return localVarFp.shopAddToCart(lineItemDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        shopCartPaid(options?: any): AxiosPromise<CartDTO> {
            return localVarFp.shopCartPaid(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        shopClearOwnCart(options?: any): AxiosPromise<CartDTO> {
            return localVarFp.shopClearOwnCart(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {LineItemDTO} lineItemDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        shopDeleteLine(lineItemDto: LineItemDTO, options?: any): AxiosPromise<CartDTO> {
            return localVarFp.shopDeleteLine(lineItemDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        shopGetAnonymousCart(options?: any): AxiosPromise<CartDTO> {
            return localVarFp.shopGetAnonymousCart(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        shopGetOwnCart(options?: any): AxiosPromise<CartDTO> {
            return localVarFp.shopGetOwnCart(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        shopGetStoreProducts(options?: any): AxiosPromise<Array<ProductDTO>> {
            return localVarFp.shopGetStoreProducts(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {CartDTO} cartDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        shopReplaceCart(cartDto: CartDTO, options?: any): AxiosPromise<CartDTO> {
            return localVarFp.shopReplaceCart(cartDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} state 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        shopUpdateCartState(state: number, options?: any): AxiosPromise<CartDTO> {
            return localVarFp.shopUpdateCartState(state, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {LineItemDTO} lineItemDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        shopUpdateLineQuantity(lineItemDto: LineItemDTO, options?: any): AxiosPromise<CartDTO> {
            return localVarFp.shopUpdateLineQuantity(lineItemDto, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * ShopApi - object-oriented interface
 * @export
 * @class ShopApi
 * @extends {BaseAPI}
 */
export class ShopApi extends BaseAPI {
    /**
     * 
     * @param {LineItemDTO} lineItemDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ShopApi
     */
    public shopAddToCart(lineItemDto: LineItemDTO, options?: any) {
        return ShopApiFp(this.configuration).shopAddToCart(lineItemDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ShopApi
     */
    public shopCartPaid(options?: any) {
        return ShopApiFp(this.configuration).shopCartPaid(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ShopApi
     */
    public shopClearOwnCart(options?: any) {
        return ShopApiFp(this.configuration).shopClearOwnCart(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {LineItemDTO} lineItemDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ShopApi
     */
    public shopDeleteLine(lineItemDto: LineItemDTO, options?: any) {
        return ShopApiFp(this.configuration).shopDeleteLine(lineItemDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ShopApi
     */
    public shopGetAnonymousCart(options?: any) {
        return ShopApiFp(this.configuration).shopGetAnonymousCart(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ShopApi
     */
    public shopGetOwnCart(options?: any) {
        return ShopApiFp(this.configuration).shopGetOwnCart(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ShopApi
     */
    public shopGetStoreProducts(options?: any) {
        return ShopApiFp(this.configuration).shopGetStoreProducts(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {CartDTO} cartDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ShopApi
     */
    public shopReplaceCart(cartDto: CartDTO, options?: any) {
        return ShopApiFp(this.configuration).shopReplaceCart(cartDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} state 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ShopApi
     */
    public shopUpdateCartState(state: number, options?: any) {
        return ShopApiFp(this.configuration).shopUpdateCartState(state, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {LineItemDTO} lineItemDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ShopApi
     */
    public shopUpdateLineQuantity(lineItemDto: LineItemDTO, options?: any) {
        return ShopApiFp(this.configuration).shopUpdateLineQuantity(lineItemDto, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * TestApi - axios parameter creator
 * @export
 */
export const TestApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        testHealthCheck: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/Test`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * TestApi - functional programming interface
 * @export
 */
export const TestApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = TestApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async testHealthCheck(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.testHealthCheck(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * TestApi - factory interface
 * @export
 */
export const TestApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = TestApiFp(configuration)
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        testHealthCheck(options?: any): AxiosPromise<any> {
            return localVarFp.testHealthCheck(options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * TestApi - object-oriented interface
 * @export
 * @class TestApi
 * @extends {BaseAPI}
 */
export class TestApi extends BaseAPI {
    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TestApi
     */
    public testHealthCheck(options?: any) {
        return TestApiFp(this.configuration).testHealthCheck(options).then((request) => request(this.axios, this.basePath));
    }
}


